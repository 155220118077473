<template>
  <!-- 机构端 - 分销管理 - 分销商城 -->
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">分销管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">分销商城</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="商品名称" class="searchboxItem">
              <span class="itemLabel">商品名称:</span>
              <el-input
                v-model="retrievalData.tradeName"
                type="text"
                size="small"
                placeholder="请输入商品名称"
                clearable
              />
            </div>
            <div title="商品分类" class="searchboxItem">
              <span class="itemLabel">商品分类:</span>
              <el-cascader
                :options="tradeTypeData"
                v-model="retrievalData.tradeType"
                :props="miniProps"
                size="small"
                clearable
              ></el-cascader>
            </div>
            <div class="df">
              <el-button type="primary" class="bgc-bv" round @click="getData()"
                >查询</el-button
              >
              <!-- <el-upload
                  style="float: right; margin-left: 1em"
                  class="upload-demo"
                  action
                  :show-file-list="false"
                  :http-request="upTemplate"
                  accept=".xlsx,.xls"
                  ><el-button type="primary">导入员工信息</el-button>
                </el-upload> -->
              <!-- // 上传导入模板
                upTemplate(res) {
                    let fileNmae = res.file.name;
                    let FileExt = fileNmae.replace(/.+\./, "");
                    if (FileExt != "xlsx" || FileExt != "xls") {
                        let formData = new FormData();
                        formData.append("file", res.file);
                        importTemplate(formData).then((res) => {
                        this.getListData(1, 10);
                        this.$message.success(res.msg);
                        });
                    } else {
                        this.$message.error("只能上传指定的模板!");
                    }
                }, -->
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
              @sort-change="changeSort"
              :default-sort="{ prop: 'auditTime', order: 'ascending' }"
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                width="100px"
              />
              <el-table-column
                label="商品名称"
                align="left"
                show-overflow-tooltip
                prop="courseName"
                min-width="200"
              />
              <el-table-column
                label="商品分类"
                align="left"
                show-overflow-tooltip
                prop="courseTypeName"
                min-width="100"
              />
              <el-table-column
                label="市场价"
                align="right"
                :show-overflow-tooltip="true"
                min-width="120"
              >
                <template slot-scope="scope">{{
                  scope.row.marketPrice || "--"
                }}</template>
              </el-table-column>
              <el-table-column
                label="分销价"
                align="right"
                :show-overflow-tooltip="true"
                min-width="120"
              >
                <template slot-scope="scope">{{
                  scope.row.distributionPrice || "--"
                }}</template>
              </el-table-column>
              <!-- <el-table-column
                label="可赚金额（每单）"
                align="left"
                :show-overflow-tooltip="true"
                min-width="120"
                >
                <template slot-scope="scope">{{
                  scope.row.distributionPrice || "--"
                }}</template>
              </el-table-column> -->
              <el-table-column
                label="操作"
                align="center"
                show-overflow-tooltip
                min-width="100"
              >
                <template slot-scope="scope">
                  <el-button
                    style="padding: 0 5px"
                    type="text"
                    size="mini"
                    @click="doSee(scope.row)"
                    >预览</el-button
                  >
                  <el-button
                    style="padding: 0 5px"
                    type="text"
                    size="mini"
                    @click="doBuy(scope.row)"
                    >购买</el-button
                  >
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog
      title="购买"
      :visible.sync="dialogVisible1"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="45%"
      :before-close="handleClose"
      ><el-form
        :model="paymentData"
        :rules="paymentData"
        ref="paymentData"
        label-width="100px"
        class="demo-ruleForm"
        ><el-form-item label="商品名称" prop="courseName">
          {{ paymentData.courseName }}
        </el-form-item>
        <el-form-item label="分销原价" prop="distributionPrice">
          <span style="color: red; font-weight: 600; font-size: 18px"
            >{{ paymentData.distributionPrice }}
            <span style="color: #959494; font-size: 14px; font-weight: 500"
              >元</span
            >
          </span>
        </el-form-item>
        <el-form-item
          label="购买方案"
          prop="stairList"
          v-if="paymentData.stairList.length >= 1"
        >
          <div
            class="stairList"
            v-for="(item, index) in paymentData.stairList"
            :key="index"
            :class="activreCss == index ? 'activreCss' : ''"
          >
            <p>
              购买数量>=<span>{{ item.stairLevel }}</span>
            </p>
            <p>
              折扣<span>{{ item.stairDiscount }}</span>
            </p>
          </div>
        </el-form-item>
        <el-form-item label="购买数量" prop="courseName">
          <el-input
            v-model="paymentData.productNumber"
            @keyup.native="
              paymentData.productNumber = zF.positiveInteger(
                paymentData.productNumber
              )
            "
            maxlength="5"
            style="width: 200px"
            placeholder="请输入购买数量"
          ></el-input>
        </el-form-item>
        <el-form-item label="支付金额" prop="courseName">
          <span
            style="color: red; font-weight: 600; font-size: 18px"
            v-if="paymentData.paymentAmount"
            >{{ paymentData.paymentAmount }}
            <span style="color: #959494; font-size: 14px; font-weight: 500"
              >元</span
            >
          </span>
          <span style="color: #cccccc" v-else>总金额</span>
        </el-form-item>
        <el-form-item label="支付方式" prop="courseName">
          <div>
            <img
              style="width: 99px; vertical-align: middle"
              src="../../assets/WePayLogo.png"
              alt=""
            />
          </div>
        </el-form-item>
        <div id="qrcode" ref="qrcode"></div>
        <!-- 放置二维码的容器,需要给一个ref -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="doBuyNo"
          class="bgc-bv"
          size="small"
          round
          style="padding: 8px 30px"
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="doBuyOrder"
          class="bgc-bv"
          size="small"
          round
          style="padding: 8px 30px"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import QRCode from "qrcodejs2"; // 引入qrcode
export default {
  name: "distributionShoppingMall",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 检索数据
      retrievalData: {
        tradeName: "", // 商品名称
        tradeType: "", // 商品分类
      },
      // 商品分类下拉数据
      tradeTypeData: [],
      // 商品分类下拉数据 - 对应数据类型
      miniProps: {
        label: "name",
        value: "courseTypeId",
        checkStrictly: false,
        emitPath: false,
      },
      // 购买的弹框
      dialogVisible1: false,
      // 购买支付的数据
      paymentData: {
        productId: "", // 商品id
        courseName: "", // 商品名称
        distributionPrice: "", // 商品原价
        stairList: [], // 商品分销阶梯价
        productNumber: "", // 购买数量
        paymentAmount: "", // 支付金额
        paymentMode: "1", // 支付方式
      },
      // 购买方案选中样式
      activreCss: null,
      // 实时获取购买是否成功
      getInfosSetInterval: null,
      // 购买后生成的订单id
      orderSn: "",
    };
  },
  computed: {},
  methods: {
    // 获取课程分类数组数据
    getcourseTypeArrData() {
      this.$post(
        "miniapp/coursetype/selectTree",
        { hiddenState: "10" },
        3000,
        true,
        2
      ).then((ret) => {
        this.tradeTypeData = this.delchild(ret.data);
      });
    },
    // 处理-获取课程分类数组数据
    delchild(list = []) {
      for (let i = 0; i < list.length; i++) {
        const el = list[i];
        if (el.children.length == 0) {
          delete el.children;
        }
        this.delchild(el.children);
      }
      return list;
    },
    // 获取列表数据
    getData(pageNum) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        courseName: this.retrievalData.tradeName,
        courseTypeId: this.retrievalData.tradeType,
        type: 20,
      };
      this.doFetch(
        {
          url: "/course/distribution/selectAll",
          params,
          pageNum,
        },
        true,
        2
      );
    },
    // 预览
    doSee(row) {
      this.$router.push({
        path: "/web/miniApp/previewCourse",
        query: {
          courseId: row.courseId,
          yj: row.marketPrice,
          courseType: row.courseTypeName,
        },
      });
    },
    // 购买
    doBuy(row) {
      this.paymentData.courseName = row.courseName;
      this.paymentData.productId = row.courseId;
      if (row.courseDistribution) {
        this.$post(
          "/course/distribution/getInfo",
          { courseDistribution: row.courseDistribution },
          3000,
          true,
          2
        ).then((res) => {
          ;
          res.data.stairList.sort((a, b) => {
            return b.stairLevel - a.stairLevel;
          });

          this.paymentData.distributionPrice = res.data.distributionPrice;
          this.paymentData.stairList = res.data.stairList;
          this.dialogVisible1 = true;
        });
      }
    },
    // 购买 - 下载订单
    doBuyOrder() {
      if (this.paymentData.productNumber) {
        this.$post(
          "/wechat/pay/unified/order",
          {
            productId: this.paymentData.productId,
            productNumber: this.paymentData.productNumber,
            productType: 1,
            tradeType: "NATIVE",
          },
          3000,
          true,
          2
        ).then((res) => {
          this.orderSn = res.data.prepayId;
          this.crateQrcode(res.data.codeURL);
        });
      } else {
        this.$message({
          message: "请输入购买数量!",
          type: "warning",
        });
      }
    },
    // 生成二维码
    crateQrcode(codeURL) {
      this.$refs.qrcode.innerHTML = "";
      this.qr = new QRCode("qrcode", {
        width: 150,
        height: 150, // 高度
        text: codeURL, // 二维码内容
        render: "canvas", // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
      });
      this.getInfosSetInterval = setInterval(() => {
        this.getInfos();
      }, 3000);
    },
    // 实时获取购买是否成功
    getInfos() {
      this.$post(
        "/order/drp-order-main/detail",
        {
          orderSn: this.orderSn,
        },
        3000,
        true,
        2
      ).then((res) => {
        if (res.data.orderStatus == 1) {
          clearInterval(this.getInfosSetInterval); // 清除定时器
          this.getInfosSetInterval = null;
          let jsonData = JSON.stringify(res.data);
          this.$router.push({
            path: "/web/jgDistribution/paySuccess",
            query: {
              jsonData,
            },
          });
        }
      });
    },
    // 取消购买
    doBuyNo() {
      clearInterval(this.getInfosSetInterval); // 清除定时器
      this.getInfosSetInterval = null;
      this.$refs.qrcode.innerHTML = "";
      this.paymentData.productNumber = "";
      this.dialogVisible1 = false;  
    },
  },
  // 离开这个页面也要清除
  beforeDestroy() {
    clearInterval(this.getInfosSetInterval); // 清除定时器
    this.getInfosSetInterval = null;
  },
  watch: {
    // 购买 - 输入购买数量
    "paymentData.productNumber": function (newval, oldval) {
      if (newval) {
        clearInterval(this.getInfosSetInterval); // 清除定时器
        this.getInfosSetInterval = null;
        this.$refs.qrcode.innerHTML = "";
        if (this.paymentData.stairList.length >= 1) {
          for (let i = 0; i < this.paymentData.stairList.length; i++) {
            if (
              this.paymentData.productNumber >=
              this.paymentData.stairList[i].stairLevel
            ) {
              this.paymentData.paymentAmount = (
                (this.paymentData.productNumber *
                  this.paymentData.distributionPrice *
                  this.paymentData.stairList[i].stairDiscount) /
                10
              ).toFixed(2);
              this.activreCss = i;
              break;
            } else {
              this.paymentData.paymentAmount = (
                this.paymentData.productNumber *
                this.paymentData.distributionPrice
              ).toFixed(2);
              this.activreCss = null;
            }
          }
        } else {
          this.paymentData.paymentAmount = (
            this.paymentData.productNumber * this.paymentData.distributionPrice
          ).toFixed(2);
          this.activreCss = null;
        }
      } else {
        this.paymentData.paymentAmount = "";
        this.activreCss = null;
      }
    },
  },
  created() {
    this.getcourseTypeArrData();
  },
};
</script>
<style lang="less">
.activreCss {
  border-color: cornflowerblue !important;
}
.stairList {
  position: relative;
  width: 24%;
  float: left;
  margin-right: 1%;
  padding: 4px 0;
  border: 1px solid #f3f3f3;
  border-radius: 4px;
  margin-bottom: 10px;
  p {
    line-height: 26px;
    padding-left: 26px;
    span {
      font-size: 18px;
      color: cornflowerblue;
      padding-left: 6px;
    }
  }
}
#qrcode {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 14px;
  width: 150px;
  margin: auto;
  img {
    background-color: #999; //设置白色背景色
  }
}
</style>